import * as angular from 'angular';
import * as moment from 'moment';
import { AssetClassEnum } from '@indicina/swan-shared/enums/AssetClassEnum';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { SWANConstants } from '@common/SWANConstants';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { healthIndexColourScheme, IDashboardParams } from '@common/models/interfaces';
import { ColourSchemeService } from '@services/colour-scheme.service';
import { HealthIndexService } from '@services/health-index.service';
import { LanguageService } from '@services/language.service';
import { LocalStorageService } from '@services/local-storage.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { SiteService } from '@services/account/site.service';
import { BaseController } from 'src/app/base.controller';

class MapControlController extends BaseController {
  public dashboardAccountInfo: fuse.dashboardAccountInfoDto;
  public availableAssetClasses: fuse.assetClassDto[];
  public siteService: SiteService;

  public areaUnit: uomUnit;
  public isEditModeDisabled: boolean;
  public isPoint = false;
  public isPolygon = false;
  public isCircle = false;

  public isPanning = true;
  public isDrawingPoint = false;
  public isDrawingPolygon = false;
  public isDrawingCircle = false;

  public assetClassName: string;
  public totalArea: number;

  /* NDVI-MapLayer */
  public toggleNdviSettings = 0;

  public eeLayer: any;
  public google: any;
  public ndviOnOff: boolean = false;
  public ndviProvider: fuse.dashboardDataProviderSubscriptionDto;
  public cloudiness: number = 100;
  public opacity: number = 0.9;

  /* 5701 - NDVI scale needs key (added _leg images) */
  public myNdviTheme: healthIndexColourScheme = null;

  public dashboardParams: IDashboardParams;
  public exactArea: number;

  private _http: angular.IHttpService;
  private _q: angular.IQService;
  private _colourSchemeService: ColourSchemeService;
  private _healthIndexService: HealthIndexService;
  private _languageService: LanguageService;
  private _localStorageService: LocalStorageService;
  private _notifyingService: NotifyingService;

  private _timeout: number;

  constructor(
    $http: angular.IHttpService,
    $q: angular.IQService,
    $scope: angular.IScope,
    ColourSchemeService: ColourSchemeService,
    HealthIndexService: HealthIndexService,
    LanguageService: LanguageService,
    LocalStorageService: LocalStorageService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    SiteService: SiteService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._q = $q;
    this._colourSchemeService = ColourSchemeService;
    this._healthIndexService = HealthIndexService;
    this._languageService = LanguageService;
    this._localStorageService = LocalStorageService;
    this._notifyingService = NotifyingService;

    this.siteService = SiteService;
    this.areaUnit = UnitOfMeasureService.getUnits('Area');

    // self.getAPIToken(); // initialise the appserver and ee tokens

    this.setEditModeState();

    this.siteService.equipments = SWANConstants.assetClasses.filter((a) => a.siteMap);
    this.availableAssetClasses = this.getAvailableAssetClasses();

    this._notifyingService.subscribe(NotifyEvents.Map.FullScreenModeChanged, $scope, (event, data) => {
      this.setEditModeState(data.isFullScreenMode);
    });

    this._notifyingService.subscribe(NotifyEvents.Map.DrawingModeSelected, $scope, (event, data) => {
      this.selectedDrawingChanged(data.drawingMode);
    });

    this._notifyingService.subscribe(NotifyEvents.Map.EquipmentReset, $scope, (event, data) => {
      this.assetClassName = null;
      this.isPanning = true;
      this.isPoint = false;
      this.isPolygon = false;
      this.isCircle = false;
    });

    this._notifyingService.subscribe(NotifyEvents.Map.MarkerSelected, $scope, (event, data) => {
      this.siteService.selectedAssetClass = this.siteService.equipments.find((a) => a.id == data.feature.assetClassId);
      this._notifyingService.notify(NotifyEvents.Map.AssetClassChanged, { equipment: this.siteService.selectedAssetClass });
    });

    this.getAccountDetail();
    this.getDashboardParams();
    this.myNdviTheme = this._colourSchemeService.healthThemes.find((c) => c.healthIndex == 'NDVI');
  }

  public get map(): google.maps.Map {
    return this.siteService.getSiteMap();
  }

  public handleChangeTab(): void {
    // Delay is required due to the custom map control container size is animated transformation
    // and thus takes some time to achieve the final container height.
    setTimeout(() => {
      // This ensures the full screen control's button position is recalculated based on the custom map control container size.
      this.map?.setZoom(this.map.getZoom());
    }, 500);
  }

  private getAvailableAssetClasses(): fuse.assetClassDto[] {
    const types = [];

    this.siteService.equipments.forEach((type) => {
      if (type.id === AssetClassEnum.SiteHealthArea || type.id === AssetClassEnum.SiteHealthPoint) {
        if (this.apf.hasSiteMapHealthFull) {
          types.push(type);
        }
      } else if (type.id === AssetClassEnum.WeatherStation) {
        if (this.apf.hasSiteMapWeatherStationFull) {
          types.push(type);
        }
      } else if (type.id == AssetClassEnum.WaterSamplePoint) {
        // if (<permission>) {
        types.push(type);
        // }
      } else if (type.id == AssetClassEnum.SoilMoisture) {
        if (this.apf.hasSiteMapSoilMoistureFull) {
          types.push(type);
        }
      } else {
        types.push(type);
      }
    });

    return types;
  }

  private getDashboardParams(): void {
    this.dashboardParams = this._localStorageService.get('dashboardParams') as IDashboardParams;
  }

  private getAccountDetail(): angular.IPromise<any> {
    const defer = this._q.defer();
    const params = { accountId: this.accountId };

    this._http.get(CommonHelper.getApiUrl('dashboard/getAccountInfo'), { params: params }).then(
      (response) => {
        if (!response.data) {
          this._languageService.whoops();
          defer.reject();
        } else {
          this.dashboardAccountInfo = response.data as fuse.dashboardAccountInfoDto;
          this.ndviProvider = this.dashboardAccountInfo.dashboardDataProviderSubscriptions.find((s) => s.dataProviderName == 'Sentinel-2');
          defer.resolve();
        }
      },
      (reject) => defer.reject(reject),
    );

    return defer.promise;
  }

  // NDVI-Layer

  // ^^^^^^^^^^^^^^^^^^^^^^^^^ START NDVI Layer  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  // Sentinel & LandSat NDVI Layers
  public ndviMapLayer(theVal: string): void {
    /* From HTML to turn layer on or off */
    if (!this.ndviOnOff) {
      // FALSE - Turn Off <-- not needed anymore this if then statement ()
      if (this.eeLayer) {
        this.map.overlayMapTypes.clear();
        this.eeLayer = null;
      }
      document.getElementById('ndviDate').innerHTML = '';
    } else {
      if (this.ndviProvider.dataProviderName == 'Sentinel-2' && this.map.getZoom() < 11) {
        this._languageService.warning('AC.SITE.MSG.EXCEEDED_ZOOM_CAPABILITIES', 'AC.SITE.MSG.ZOOM_LEVEL_NOT_OPTIMAL');
        this.ndviProvider = this.dashboardAccountInfo.dashboardDataProviderSubscriptions.find((s) => s.dataProviderName == 'Landsat-8');
        this.displayMap(this.ndviProvider.eeConfig, theVal);
      } else {
        this.displayMap(this.ndviProvider.eeConfig, theVal);
      }
      this.getHealthIndexLatestDate();
    }
  }

  public turnNdviLayerOnOff(): void {
    if (!this.ndviOnOff) {
      this.ndviOnOff = true;
      this.ndviMapLayer('NDVI');
    } else {
      this.ndviOnOff = false;
      if (this.eeLayer) {
        this.map.overlayMapTypes.clear();
        this.eeLayer = null;
      }
      document.getElementById('ndviDate').innerHTML = '';
    }
  }

  // User changed sensor selection
  public setNdviSensor(): void {
    this.ndviMapLayer('NDVI');
  }

  // User changed cloudiness value
  public setNdviCloudiness(): void {
    this.ndviMapLayer('NDVI');
  }

  // Date search in days from today to find imagery - primarily when 99% cloud free is selected
  public setNdviTimeSearch(): void {
    // Set the time search - default 90 days (3 months). Increments in 90 days up to 3 years
    this.ndviMapLayer('NDVI');
  }

  // Layer Opacity
  public setNdviOpacity(): void {
    if (this.eeLayer) {
      this.eeLayer.setOpacity(this.opacity);
    }
  }

  public setNdviTheme(): void {
    // Set the theme
    this._colourSchemeService.saveHealthThemes();
    this.ndviMapLayer('NDVI');
  }

  // Primary GEE Map Tile Display based on user selected inputs
  public displayMap(providerConfig: string, index: string): void {
    try {
      this._healthIndexService
        .createHealthIndexMapLayer(providerConfig, index, this.cloudiness, false, null, moment().toDate(), 0, 1)
        .then(
          (layer) => {
            if (this.eeLayer) {
              this.map.overlayMapTypes.clear();
              this.eeLayer = null;
            }
            this.eeLayer = layer;
            this.map.overlayMapTypes.push(this.eeLayer);
            this.getHealthIndexLatestDate();
          },
          (e) => {
            console.error(e);
            this._languageService.info('PROJ.NO_IMAGES_FOUND');
          },
        );
    } catch (e) {
      console.error(e);
      this._languageService.info('PROJ.NO_IMAGES_FOUND');
    }
  }

  private getHealthIndexLatestDate(): void {
    const mapBounds = this.map.getBounds();
    const ele = document.getElementById('ndviDate');

    window.clearTimeout(this._timeout);

    this._timeout = window.setTimeout(() => {
      try {
        this._healthIndexService
          .getImageInfo(
            this.ndviProvider.eeConfig,
            this.cloudiness,
            mapBounds.getSouthWest().lat(),
            mapBounds.getSouthWest().lng(),
            mapBounds.getNorthEast().lat(),
            mapBounds.getNorthEast().lng(),
            moment().toDate(),
            90,
          )
          .then(
            (info) => {
              if (info) {
                ele.innerHTML = this._languageService.instant('AC.SITE.MAPS.IMAGERY_DATE', {
                  date: DateUtils.Locale.asDateMedium(info.date),
                });
              } else {
                ele.innerHTML = this._languageService.instant('AC.SITE.MAPS.NO_IMAGERY_FOUND');
              }
            },
            (reason) => {
              ele.innerHTML = this._languageService.instant('AC.SITE.MAPS.NO_IMAGERY_FOUND');
            },
          );
        // Display the time range viewer - can error on QPS
      } catch (e) {
        console.log('Unable to get Health Index Image Date: ' + e);
      }
    }, 1000);
  }

  public openNdviSettingsPanel() {
    this.toggleNdviSettings = 1; //set to 1 to display the panel
  }

  // vvvvvvvvvvvvvvvvvvvvvvvvv   END NDVI Layer  vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv

  public mapLayerInfo(mapType: string): void {
    let theTitle: string;
    let theContent: string;

    if (mapType == 'st') {
      theTitle = this._languageService.instant('COMMON.GOOGLE_STREET');
      theContent = `${this._languageService.instant('AC.SITE.GOOGLE_STREET_TIP')} ${this._languageService.instant(
        'COMMON.GOOGLE_MAPS_TERMS',
      )}`;
    } else if (mapType == 'gt') {
      theTitle = this._languageService.instant('COMMON.GOOGLE_TERRAIN');
      theContent = `${this._languageService.instant('AC.SITE.GOOGLE_TERRAIN_TIP')} ${this._languageService.instant(
        'COMMON.GOOGLE_MAPS_TERMS',
      )}`;
    } else if (mapType == 'gi') {
      theTitle = this._languageService.instant('COMMON.GOOGLE_VISIBLE');
      theContent = `${this._languageService.instant('AC.SITE.GOOGLE_VISIBLE_TIP')} ${this._languageService.instant(
        'COMMON.GOOGLE_MAPS_TERMS',
      )}`;
    } else if (mapType == 'ndvi') {
      theTitle = this._languageService.instant('DB_VALUES.DATAINPUTTYPES.NDVI');
      theContent = `${this._languageService.instant('AC.SITE.NDVI_TIP1')} `;
      theContent += "<br><br><img src='assets/images/logos/legend_ndvi.png'><br><br>";
      theContent += `${this._languageService.instant(
        'AC.SITE.NDVI_TIP2',
      )} <a href='https://earthengine.google.com/' target='_blank'>${this._languageService.instant(
        'COMMON.GOOGLE_EARTH_ENGINE',
      )}.</a>`;
    }

    this._languageService.show(
      this._languageService
        .alert()
        .parent(angular.element(document.querySelector('#popupContainer')))
        .clickOutsideToClose(true)
        .parent(angular.element(document.body))
        .title(theTitle)
        .htmlContent(theContent)
        .ariaLabel(theTitle)
        .ok('COMMON.CLOSE')
        .targetEvent(),
    );
  }

  public handleChangeEditMode(): void {
    if (!this.siteService.isEditMode) {
      this.resetDrawingSelection();
    } else {
      this.isPanning = true;
    }

    this.isPoint = false;
    this.isPolygon = false;
    this.isCircle = false;

    this._notifyingService.notify(NotifyEvents.Map.EditModeChanged, {
      isEditMode: this.siteService.isEditMode,
      equipment: this.siteService.selectedAssetClass,
    });
  }

  public mapLayerChange(mapTypeId): void {
    // Change the map layers from the Layer Control

    switch (mapTypeId) {
      case google.maps.MapTypeId.ROADMAP:
      case google.maps.MapTypeId.TERRAIN:
      case google.maps.MapTypeId.HYBRID:
      case 'NDVI':
      case 'EVI':
      case 'NDWI':
      case 'FNIR':
      case 'OSM':
      case 'OTM':
        this._notifyingService.notify(NotifyEvents.Map.MapTypeChanged, { mapTypeId });
        break;
    }
  }

  public handleChangeDrawingOption(): void {
    this.resetDrawingSelection();
    this.isPanning = false;

    const assetClass = this.availableAssetClasses.find(x => x.name === this.assetClassName);

    if (assetClass?.mappable === 'Point') {
      this.isPoint = true;
      this.isPolygon = !this.isPoint;
      this.isCircle = !this.isPoint;
      this.isDrawingPoint = true;
    } else if (assetClass?.mappable === 'Polygon') {
      if (assetClass.name == 'Site (IMU)' && !this.siteService.isSiteGeometryCreated) {
        this._notifyingService.notify(NotifyEvents.Map.UploadMapData, { equipment: assetClass });
      }

      this.isPoint = false;
      this.isPolygon = !this.isPoint;
      this.isCircle = !this.isPoint;
      this.isDrawingPolygon = true;
    }

    this.siteService.selectedAssetClass = assetClass;

    this._notifyingService.notify(NotifyEvents.Map.AssetClassChanged, { equipment: assetClass });
    this._notifyingService.notify(NotifyEvents.Map.DrawingModeChanged, { drawingMode: assetClass?.mappable });
    this._notifyingService.notify(NotifyEvents.Map.EditModeChanged, {
      isEditMode: this.siteService.isEditMode,
      equipment: assetClass,
    });
  }

  private setEditModeState(isFullScreenMode = false): void {
    this.isEditModeDisabled = !this.apf.hasSiteMapDrawing || isFullScreenMode;
  }

  private resetDrawingSelection(): void {
    this.isPanning = false;
    this.isDrawingCircle = false;
    this.isDrawingPoint = false;
    this.isDrawingPolygon = false;
  }

  public selectedDrawingChanged(selection: string): void {
    this.resetDrawingSelection();

    switch (selection) {
      case 'Hand':
        this.isPanning = true;
        break;
      case 'Circle':
        this.isDrawingCircle = true;
        break;
      case 'Point':
        this.isDrawingPoint = true;
        break;
      case 'Polygon':
        this.isDrawingPolygon = true;
        break;

      default:
    }

    this._notifyingService.notify(NotifyEvents.Map.DrawingModeChanged, { drawingMode: selection });
  }

  public totalAreaChanged(): void {
    this._notifyingService.notify(NotifyEvents.Map.TotalAreaChanged, { exactArea: this.exactArea });
  }
}

angular.module('app.account').controller('MapControlController', MapControlController);
