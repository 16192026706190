// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#infrastructure-depth md-input-container {
  margin: 0;
  padding: 0;
  height: 50px;
  vertical-align: bottom;
}
#infrastructure-depth md-input-container .md-errors-spacer {
  min-height: 0px;
}
#infrastructure-depth label {
  font-weight: bold;
}
#infrastructure-depth #depth-table {
  overflow: auto;
  max-height: calc(100vh - 410px);
}
#infrastructure-depth #depth-table table {
  width: 100%;
  border-collapse: collapse;
}
#infrastructure-depth #depth-table table thead tr {
  position: sticky;
  z-index: 10;
  height: 24px;
  top: 0;
}
#infrastructure-depth #depth-table table thead tr th {
  background-color: #666;
  color: white;
  padding: 0 8px;
  font-size: 14px;
}
#infrastructure-depth #depth-table table tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  height: 36px;
}
#infrastructure-depth #depth-table table tbody tr td {
  padding: 0 8px;
  font-size: 14px;
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/account/views/infrastructure/depth.scss"],"names":[],"mappings":"AACI;EACI,SAAA;EACA,UAAA;EACA,YAAA;EACA,sBAAA;AAAR;AAEQ;EACI,eAAA;AAAZ;AAII;EACI,iBAAA;AAFR;AAKI;EACI,cAAA;EACA,+BAAA;AAHR;AAKQ;EACI,WAAA;EACA,yBAAA;AAHZ;AAMgB;EACI,gBAAA;EACA,WAAA;EACA,YAAA;EACA,MAAA;AAJpB;AAMoB;EACI,sBAAA;EACA,YAAA;EACA,cAAA;EACA,eAAA;AAJxB;AAUgB;EACI,4CAAA;EACA,YAAA;AARpB;AAUoB;EACI,cAAA;EACA,eAAA;EACA,uBAAA;AARxB","sourcesContent":["#infrastructure-depth {\r\n    md-input-container {\r\n        margin: 0;\r\n        padding: 0;\r\n        height: 50px;\r\n        vertical-align: bottom;\r\n\r\n        .md-errors-spacer {\r\n            min-height: 0px;\r\n        }\r\n    }\r\n\r\n    label {\r\n        font-weight: bold;\r\n    }\r\n\r\n    #depth-table {\r\n        overflow: auto;\r\n        max-height: calc(100vh - 410px);\r\n\r\n        table {\r\n            width: 100%;\r\n            border-collapse: collapse;\r\n\r\n            thead {\r\n                tr {\r\n                    position: sticky;\r\n                    z-index: 10;\r\n                    height: 24px;\r\n                    top: 0;\r\n\r\n                    th {\r\n                        background-color: #666;\r\n                        color: white;\r\n                        padding: 0 8px;\r\n                        font-size: 14px;\r\n                    }\r\n                }\r\n            }\r\n\r\n            tbody {\r\n                tr {\r\n                    border-bottom: 1px solid rgba(0, 0, 0, 0.12);\r\n                    height: 36px;\r\n\r\n                    td {\r\n                        padding: 0 8px;\r\n                        font-size: 14px;\r\n                        background-color: white;\r\n                    }\r\n                }\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
