import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { unitSizes } from '@common/enums';
import { IIdNameItem } from '@common/models/interfaces';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { BaseController } from 'src/app/base.controller';
import { CopySettingsDialogController } from '../copysettings-dialog.controller';

class SettingsDepthTabComponent implements angular.IComponentOptions {
  bindings = {
    infraAssetId: '<',
    depthVolumes: '<',
    isAdd: '<',
    isAnyChange: '<',
    selectedDate: '<',
    refreshCount: '<',
    onChange: '&',
  };
  controller = SettingsDepthTabController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/account/views/infrastructure/components/settings-depth-tab.html';
}

class SettingsDepthTabController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _timeout: angular.ITimeoutService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _notifyingService: NotifyingService;

  public filterDates: (date: Date) => boolean;

  private onChange: ({ isValid, date, oriDate }: { isValid: boolean; date: Date; oriDate: Date | null }) => void;
  public depthsForm: angular.IFormController;
  private depthVolumes: fuse.depthVolumeDto[];
  public filteredDepthVolumes: fuse.depthVolumeDto[];
  public depthSettings: IIdNameItem[] = [];
  public selectedDepthSetting: IIdNameItem;
  public selectedDate: Date;
  private adjustedTodayDayNumber: number;
  private adjustedTodayDate: Date;
  public isNewInfrastructure: boolean;
  public volumeNormalUnit: string;
  public elevationSmallUnit: string;
  private infraAssetId: number;
  public isAdd: boolean;
  public isAnyChange: boolean;
  private isDatePickerOpened = false;
  public isDateChanged = false;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    $timeout: angular.ITimeoutService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._timeout = $timeout;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._notifyingService = NotifyingService;

    this.filterDates = (date: Date): boolean => {
      if (!this.isDatePickerOpened) {
        return true;
      }

      if (this.isDateChanged && !this.isAdd) {
        // the date which is 'from' is a valid date
        if ((this.selectedDepthSetting.value as Date).getTime() == date.getTime()) {
          return true;
        }

        if (this.selectedDate.getTime() == date.getTime()) {
          return true;
        }
      }

      return this.depthSettings.every((a) => (a.value as Date).getTime() != date.getTime());
    };

    this.volumeNormalUnit = UnitOfMeasureService.getUnitLabel('Volume', unitSizes.normal);
    this.elevationSmallUnit = UnitOfMeasureService.getUnitLabel('Elevation', unitSizes.small);

    this.adjustedTodayDayNumber = DayNumberService.convertBrowserDateTimeToLocaleDayNumber();
    this.adjustedTodayDate = this._dayNumberService.convertDayNumberToLocaleDate(this.adjustedTodayDayNumber);
  }

  $onChanges(changes) {
    if (changes.depthVolumes?.currentValue) {
      this.isDateChanged = false;

      if (this.depthVolumes.length) {
        this.createDepthSettings();
      }
    }

    if (changes.infraAssetId) {
      if (!changes.infraAssetId.currentValue) {
        // new water store
        this.isNewInfrastructure = true;

        this.selectedDepthSetting = {
          id: this.adjustedTodayDayNumber,
          value: this.adjustedTodayDate,
        } as IIdNameItem;

        this.selectedDate = (this.selectedDepthSetting.value as Date).clone();

        this.depthVolumes.push({
          dayNumber: this.adjustedTodayDayNumber,
          date: this.adjustedTodayDate,
          volumeKL: 0,
        } as fuse.depthVolumeDto);

        this.depthVolumes.push({ dayNumber: this.adjustedTodayDayNumber, date: this.adjustedTodayDate } as fuse.depthVolumeDto);

        this.filterDepthVolumes();
        this.depthSettings = [];

        this._timeout(() => {
          this.setFormTouched();
          this.onChange({ isValid: this.depthsForm.$valid, date: this.selectedDate, oriDate: null });
        }, 100);
      } else {
        this.isNewInfrastructure = false;
      }
    }

    if (changes.isAdd?.currentValue) {
      // add new depthsetting
      this.selectedDate = null;
      this.depthVolumes.push({ volumeKL: 0 } as fuse.depthVolumeDto);
      this.depthVolumes.push({} as fuse.depthVolumeDto);

      this.filterDepthVolumes();

      this._timeout(() => {
        this.setFormTouched();
        this.onChange({ isValid: this.depthsForm.$valid, date: this.selectedDate, oriDate: null });
      }, 100);
    }

    if (changes.selectedDate?.currentValue) {
      if (!this.isAdd && !this.isDateChanged) {
        this.createDepthSettings();
        this.filterDepthVolumes();

        this.selectedDepthSetting = this.depthSettings.find((a) => (a.value as Date).getTime() == this.selectedDate.getTime());
      }
    }

    if (changes.refreshCount?.currentValue > 0) {
      this.createDepthSettings();
      this.filterDepthVolumes();

      this.selectedDepthSetting = this.depthSettings.find((a) => (a.value as Date).getTime() == this.selectedDate.getTime());
    }
  }

  private createDepthSettings() {
    this.depthSettings = [];

    this.depthVolumes.forEach((depthVolume) => {
      if (this.depthSettings.every((a) => a.id !== depthVolume.dayNumber)) {
        this.depthSettings.push({
          id: depthVolume.dayNumber,
          value: depthVolume.date,
          name: DateUtils.Locale.asDateMedium(depthVolume.date),
        } as IIdNameItem);
      }
    });

    this.depthSettings = ArrayUtils.sortByNumber(this.depthSettings, (x) => x.id as number);

    if (this.selectedDate == null) {
      this.selectedDepthSetting = this.depthSettings.filter((a) => (a.id as number) <= this.adjustedTodayDayNumber).pop();

      if (this.selectedDepthSetting == null) {
        this.selectedDepthSetting = this.depthSettings.find((a) => a.id as number);
      }

      if (this.selectedDepthSetting != null) {
        this.selectedDate = (this.selectedDepthSetting.value as Date).clone();
        this.filterDepthVolumes();
      } else {
        // new waterstore
        // do nothing
      }
    }
  }

  private filterDepthVolumes() {
    const selectedDateInMs = this.selectedDate?.getTime();
    const predicate = selectedDateInMs
      ? (x: fuse.depthVolumeDto) => x.date.getTime() == selectedDateInMs
      : (x: fuse.depthVolumeDto) => !x.date;

    this.filteredDepthVolumes = this.depthVolumes.filter(predicate);
  }

  public copySet() {
    this._mdDialog
      .show({
        clickOutsideToClose: false,
        escapeToClose: false,
        controller: CopySettingsDialogController,
        controllerAs: 'vm',
        parent: angular.element(document.body),
        templateUrl: 'src/app/pages/account/views/infrastructure/copysettings-dialog.html',
        locals: {
          assetId: this.infraAssetId,
          existedDates: this.depthVolumes.filter((a) => a.date != null).map((a) => a.date),
          depthVolumes: this.filteredDepthVolumes,
        },
      })
      .then((res) => {
        if (res) {
          this._languageService.success('COMMON.CHANGES_SAVED');
        }
      });
  }

  public deleteSet() {
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('COMMON.DELETE'))
      .textContent(this._languageService.instant('AC.INFRASTRUCTURE.DELETE_DEPTHSET', { date: DateUtils.Locale.asDateMedium(this.selectedDate) }))
      .ok(this._languageService.instant('COMMON.CONFIRM'))
      .cancel(this._languageService.instant('COMMON.CANCEL'));

    this._mdDialog.show(confirm).then((res) => {
      if (res) {
        const dayNumber = this.selectedDepthSetting.id as number;
        this._http
          .delete(
            CommonHelper.getApiUrl(`infrastructure/deletedepthset?assetid=${this.infraAssetId}&dayNumber=${dayNumber}`),
          )
          .then(
            () => {
              // show the one which is effective today. if none, show the first one
              let depthSetting = this.depthSettings
                .filter((a) => (a.id as number) <= this.adjustedTodayDayNumber && a.id != dayNumber)
                .pop();

              if (!depthSetting) {
                depthSetting = this.depthSettings.find((a) => (a.id as number) > this.adjustedTodayDayNumber && a.id != dayNumber);
              }

              this._notifyingService.notify(NotifyEvents.Infrastructure.Refresh.DepthSetting, { date: (depthSetting.value as Date).clone() });
              this._languageService.success('COMMON.CHANGES_SAVED');
            },
            () => {
              this._languageService.whoops();
            },
          );
      }
    });
  }

  public changeDepthVolume() {
    let previousDepth = null;
    let previousVolume = null;

    for (let i = 0; i < this.filteredDepthVolumes.length; i++) {
      const depth = this.depthsForm['depth' + i].$modelValue;
      const volume = this.depthsForm['volume' + i].$modelValue;

      if (this.filteredDepthVolumes.filter((a) => a.depthM == depth).length > 1) {
        this.depthsForm['depth' + i].$setValidity('valid', false);
      } else {
        this.depthsForm['depth' + i].$setValidity('valid', true);
      }

      if (depth != null) {
        if (previousDepth != null && depth <= previousDepth) {
          this.depthsForm['depth' + i].$setValidity('order', false);
          this.depthsForm['depth' + i].$setTouched();
        } else {
          this.depthsForm['depth' + i].$setValidity('order', true);
        }

        previousDepth = depth;
      } else {
        this.depthsForm['depth' + i].$setTouched();
      }

      if (volume != null) {
        if (previousVolume != null && volume <= previousVolume) {
          this.depthsForm['volume' + i].$setValidity('order', false);
          this.depthsForm['volume' + i].$setTouched();
        } else {
          this.depthsForm['volume' + i].$setValidity('order', true);
        }

        previousVolume = volume;
      } else {
        this.depthsForm['volume' + i].$setTouched();
      }
    }

    this.onChange({ isValid: this.depthsForm.$valid, date: this.selectedDate, oriDate: null });
  }

  public addDepthVolume(item: fuse.depthVolumeDto) {
    const index = this.depthVolumes.findIndex((a) => a == item);

    if (this.selectedDate == null) {
      this.depthVolumes.splice(index + 1, 0, {} as fuse.depthVolumeDto);
    } else {
      this.depthVolumes.splice(index + 1, 0, {
        dayNumber: this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.selectedDate),
        date: this.selectedDate,
      } as fuse.depthVolumeDto);
    }

    this.filterDepthVolumes();

    this._timeout(() => {
      this.changeDepthVolume();
    }, 100);
  }

  public deleteDepthVolume(item: fuse.depthVolumeDto) {
    const index = this.depthVolumes.findIndex((a) => a == item);

    this.depthVolumes.splice(index, 1);
    this.filterDepthVolumes();

    this._timeout(() => {
      this.changeDepthVolume();
    }, 100);
  }

  private setFormTouched() {
    angular.forEach(this.depthsForm, (field) => {
      if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
        field.$setTouched();
      }
    });
  }

  public changeSelectedDate() {
    const dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.selectedDate);

    this.filteredDepthVolumes.forEach((depthVolume) => {
      depthVolume.date = this.selectedDate;
      depthVolume.dayNumber = dayNumber;
    });

    this.isDatePickerOpened = false;
    this.isDateChanged = true;

    this._timeout(() => {
      this.onChange({ isValid: this.depthsForm.$valid, date: this.selectedDate, oriDate: this.selectedDepthSetting.value as Date });
    }, 100);
  }

  public openDatePicker() {
    this.isDatePickerOpened = true;
  }

  public closeDatePicker() {
    this.isDatePickerOpened = false;
  }

  public changeDepthSetting() {
    this.selectedDate = this.selectedDepthSetting.value as Date;
    this.filterDepthVolumes();
  }
}

angular.module('app.account').component('settingsDepthTab', new SettingsDepthTabComponent());
