// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#infrastructure md-subheader {
  position: sticky;
  top: 0;
  z-index: 2;
}
#infrastructure > md-tabs > md-tabs-wrapper {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
#infrastructure .summary-block {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin: 0 12px 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/account/views/infrastructure/infrastructure.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,MAAA;EACA,UAAA;AAAJ;AAII;EACE,+GAAA;AAFN;AAQE;EACE,+GAAA;EAGA,kBAAA;AARJ","sourcesContent":["#infrastructure {\r\n  md-subheader {\r\n    position: sticky;\r\n    top: 0;\r\n    z-index: 2;\r\n  }\r\n\r\n  >md-tabs {\r\n    >md-tabs-wrapper {\r\n      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2),\r\n        0 1px 1px 0 rgba(0, 0, 0, .14),\r\n        0 2px 1px -1px rgba(0, 0, 0, .12);\r\n    }\r\n  }\r\n\r\n  .summary-block {\r\n    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2),\r\n      0 1px 1px 0 rgba(0, 0, 0, .14),\r\n      0 2px 1px -1px rgba(0, 0, 0, .12);\r\n    margin: 0 12px 8px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
