// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gm-style-iw.gm-style-iw-c {
  padding-bottom: 12px !important;
  padding-right: 12px !important;
}
.gm-style-iw.gm-style-iw-c .gm-style-iw-chr .gm-ui-hover-effect {
  height: 14px !important;
  width: 14px !important;
}
.gm-style-iw.gm-style-iw-c .gm-style-iw-chr .gm-ui-hover-effect span {
  margin: 6px !important;
  height: 14px !important;
  width: 14px !important;
}
.gm-style-iw.gm-style-iw-c .gm-style-iw-d {
  overflow: auto !important;
}

#map-site {
  height: calc(100vh - 221px);
  width: 100%;
}
#map-site #map-control-placeholder {
  width: 320px;
  padding: 1px;
  background-color: white;
  opacity: 0.9;
  z-index: 1 !important;
}
#map-site #map-control-placeholder #ndvi-setting {
  overflow: auto;
  max-height: calc(100vh - 520px);
}

.fullscreen-disabled {
  background-color: grey !important;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/account/views/site/map/map.scss"],"names":[],"mappings":"AAEA;EACE,+BAAA;EACA,8BAAA;AADF;AAKI;EACE,uBAAA;EACA,sBAAA;AAHN;AAKM;EACE,sBAAA;EACA,uBAAA;EACA,sBAAA;AAHR;AAQE;EACE,yBAAA;AANJ;;AAUA;EACE,2BAAA;EACA,WAAA;AAPF;AASE;EACE,YAAA;EACA,YAAA;EACA,uBAAA;EACA,YAAA;EACA,qBAAA;AAPJ;AASI;EACE,cAAA;EACA,+BAAA;AAPN;;AAYA;EACE,iCAAA;EACA,oBAAA;AATF","sourcesContent":["// Google Maps JS API versions above 3.56 have made an update to the look and feel of the 'InfoWindow' header (i.e. margins and close icon size/position changed - https://issuetracker.google.com/issues/343501092).\r\n// NOTE: We prefer the look and feel of the 'InfoWindow' header as per Google Maps JS API versions 3.56, hence we apply the override of the styles.\r\n.gm-style-iw.gm-style-iw-c {\r\n  padding-bottom: 12px !important;\r\n  padding-right: 12px !important;\r\n\r\n  .gm-style-iw-chr {\r\n\r\n    .gm-ui-hover-effect {\r\n      height: 14px !important;\r\n      width: 14px !important;\r\n\r\n      span {\r\n        margin: 6px !important;\r\n        height: 14px !important;\r\n        width: 14px !important;\r\n      }\r\n    }\r\n  }\r\n\r\n  .gm-style-iw-d {\r\n    overflow: auto !important;\r\n  }\r\n}\r\n\r\n#map-site {\r\n  height: calc(100vh - 221px);\r\n  width: 100%;\r\n\r\n  #map-control-placeholder {\r\n    width: 320px;\r\n    padding: 1px;\r\n    background-color: white;\r\n    opacity: 0.9;\r\n    z-index: 1 !important;\r\n\r\n    #ndvi-setting {\r\n      overflow: auto;\r\n      max-height: calc(100vh - 520px);\r\n    }\r\n  }\r\n}\r\n\r\n.fullscreen-disabled {\r\n  background-color: grey !important;\r\n  pointer-events: none;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
