import * as angular from 'angular';
import { UnitTypes } from '@common/enums';
import { IGoogleTimeZone } from '@indicina/swan-shared/interfaces/google/IGoogleTimeZone';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { MapService } from '@services/map.service';
import { uomUnit } from '@services/unit-of-measure.service';
import { SiteService } from '@services/account/site.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { BaseController } from 'src/app/base.controller';
import { PermissionService } from '@services/permission.service';
import { Asset } from 'src/app/_DBContext/Asset';

class SWANMapAssetPropertyViewComponent implements angular.IComponentOptions {
  bindings = {
    assetId: '<',
    reloadCounter: '<',
  };
  controller = MapAssetPropertyViewController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/account/views/site/map/asset-property-view.html';
}

class MapAssetPropertyViewController extends BaseController {
  private _q: angular.IQService;
  private _languageService: LanguageService;
  private _mapService: MapService;
  private _siteService: SiteService;

  public assetId: number;
  public timeZoneName: string;
  public reloadCounter: number;
  public elevationUnit: uomUnit;

  constructor(
    $q: angular.IQService,
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    MapService: MapService,
    PermissionService: PermissionService,
    SiteService: SiteService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._q = $q;
    this._languageService = LanguageService;
    this._mapService = MapService;
    this._siteService = SiteService;

    this.entityManager = DataEntityService.manager;
    this.elevationUnit = UnitOfMeasureService.getUnits(UnitTypes.Elevation);
  }

  public get selectedAsset(): Asset {
    return this._siteService.selectedAsset;
  }

  public get selectedMarker(): google.maps.Marker {
    return this._siteService.selectedMarker;
  }

  $onInit() {
    this.setHeightWeatherStation();
    this.validateLocation();
  }

  $onChanges(changes) {
    if (changes.assetId) {
      this.fetchAsset();
    }

    if (changes.reloadCounter) {
      this.setHeightWeatherStation();
      this.validateLocation();
    }
  }

  // Virtually the same behaviour as equipment/index-controller.validateLocation()
  public validateLocation() {
    if (this.selectedAsset && this.scope['assetPropertyForm']) {
      const latitudeRef = this.scope['assetPropertyForm'].latitude;
      const longitudeRef = this.scope['assetPropertyForm'].longitude;

      // Need to clear any errors in google_location so .$valid reflects only max/min validations.
      latitudeRef.$setValidity('google_location', true);
      longitudeRef.$setValidity('google_location', true);

      if (!latitudeRef.$valid || !longitudeRef.$valid) {
        return;
      }

      const asset = this.selectedAsset;
      const latitude = asset.Latitude;
      const longitude = asset.Longitude;

      this._q.all([
        this._mapService.getElevation(latitude, longitude),
        this._mapService.getTimeZone(latitude, longitude)
      ]).then((data) => {
        const tzData = JSON.parse(data[1].toString()) as IGoogleTimeZone;

        if (tzData.status == 'ZERO_RESULTS') {
          latitudeRef.$setValidity('google_location', false);
          latitudeRef.$setDirty();
          latitudeRef.$setTouched();

          longitudeRef.$setValidity('google_location', false);
          longitudeRef.$setDirty();
          longitudeRef.$setTouched();

          this._languageService.error('AC.SITE.MSG.LATLONG_INVALID');
        } else {
          latitudeRef.$setValidity('google_location', true);
          longitudeRef.$setValidity('google_location', true);
        }

        asset.Elevation = parseFloat(data[0].toString());
        asset.TZStandardName = data[1].toString();
      });
    }
  }

  private fetchAsset() {
    if (this.assetId) {
      // const pred = breeze.Predicate.create('AssetId', breeze.FilterQueryOp.Equals, this.assetId);

      const successCallback = (data: breeze.QueryResult) => {
        console.log(data);
      };
      const failCallback = () => {};

      breeze.EntityQuery.from('assets/' + this.assetId + '/NutrientSettings')
        .using(this.entityManager)
        .execute()
        .then(successCallback, failCallback);
    }
  }

  private setHeightWeatherStation() {
    const asset = this.selectedAsset;

    if (asset?.AssetClass?.Name !== 'Weather Station') {
      return;
    }

    if (asset.Sensor && asset.Sensor.Height == null) {
      this._siteService.selectedAsset.Sensor.Height = 10;
    }
  }
}

angular.module('app.account').component('swanMapAssetPropertyView', new SWANMapAssetPropertyViewComponent());
