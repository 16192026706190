import * as angular from 'angular';
import { AppSettings } from '@indicina/swan-shared/AppSettings';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { TableControl } from '@common/helpers/TableControl';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { AddInfrastructureDialogController } from './addinfrastructure-dialog.controller';
import { BaseController } from 'src/app/base.controller';

export class InfrastructuresController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _state: angular.ui.IStateService;
  private _languageService: LanguageService;

  public showActive = true;
  public showArchived = false;
  public showSuspended = false;
  public nameFilter = '';
  public typeFilter = '';
  public tableControl = new TableControl();
  public flowRateLargeUnit: string;

  private infrastructures = [] as fuse.infrastructureDto[];
  private filteredInfrastructures: fuse.infrastructureDto[];

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._state = $state;
    this._languageService = LanguageService;

    this.flowRateLargeUnit = UnitOfMeasureService.getUnitLabel('Flow Rate', unitSizes.large);
  }

  $onInit() {
    this._http.get(CommonHelper.getApiUrl('infrastructure/infrastructures')).then((response) => {
      if (response.data == null) {
        this._languageService.whoops();
      } else {
        this.infrastructures = response.data as fuse.infrastructureDto[];
        this.filterInfrastructures();
      }
    });
    this.scope.$watchGroup(['vm.nameFilter', 'vm.typeFilter', 'vm.showActive', 'vm.showArchived', 'vm.showSuspended'], () => {
      if (this.infrastructures.length) {
        this.filterInfrastructures();
      }
    });
  }

  public clearFilter(): void {
    this.nameFilter = '';
    this.typeFilter = '';
  }

  private filterInfrastructures() {
    if (this.infrastructures) {
      this.filteredInfrastructures = this.infrastructures.filter(
        (a) => a.name.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1,
      );
      this.filteredInfrastructures = this.filteredInfrastructures.filter(
        (a) => a.assetClassName.toLowerCase().indexOf(this.typeFilter.toLowerCase()) > -1,
      );

      if (!this.showActive) {
        this.filteredInfrastructures = this.filteredInfrastructures.filter((a) => a.status !== 'Active');
      }

      if (!this.showArchived) {
        this.filteredInfrastructures = this.filteredInfrastructures.filter((a) => a.status !== 'Archived');
      }

      if (!this.showSuspended) {
        this.filteredInfrastructures = this.filteredInfrastructures.filter((a) => a.status !== 'Suspended');
      }
    }
  }

  public addInfrastructure() {
    this._mdDialog
      .show({
        controller: AddInfrastructureDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/views/infrastructures/addinfrastructure-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          infrastructures: this.infrastructures,
        },
      })
      .then((result) => {
        if (result) {
          this._state.go('app.account.infrastructures.detail', {
            id: null,
            name: result.name,
            assetClassId: result.assetClassId,
          });
        }
      });
  }

  public gotoInfrastructure(assetId: number) {
    this._state.go('app.account.infrastructures.detail', { id: assetId });
  }

  public openKnowledgeBase() {
    const baseUrlWithoutTrailingSlash = AppSettings.app.knowledgeBaseUrl.replace(/\/$/, '');

    window.open(`${baseUrlWithoutTrailingSlash}/books/water-supply-module`, '_blank');
  }
}

angular.module('app.account').controller('InfrastructuresController', InfrastructuresController);
