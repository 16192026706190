import * as angular from 'angular';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { DayNumberService } from '@services/day-number.service';
import { BaseController } from 'src/app/base.controller';

export class CopySettingsDialogController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _notifyingService: NotifyingService;

  public filterDates: (date: Date) => boolean;
  public copyDate: Date;
  public oriDate: Date;

  private existedDates: Date[];
  private assetId: number;
  private depthVolumes = [] as fuse.depthVolumeDto[];

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    assetId: number,
    existedDates: Date[],
    depthVolumes: fuse.depthVolumeDto[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._notifyingService = NotifyingService;

    this.assetId = assetId;
    this.existedDates = existedDates;
    this.depthVolumes = angular.copy(depthVolumes);
    this.oriDate = this.depthVolumes[0].date.clone();

    this.filterDates = (date: Date): boolean => {
      return this.existedDates.every((a) => a.getTime() != date.getTime());
    };

    this.copyDate = this._dayNumberService.convertBrowserDateTimeToLocaleDate();
  }

  public copy() {
    const dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.copyDate);
    this.depthVolumes.forEach((depthVolume) => {
      depthVolume.dayNumber = dayNumber;
    });
    this._http
      .put(
        CommonHelper.getApiUrl(`infrastructure/savedepthvolumes?assetid=${this.assetId}&oridaynumber=null`),
        this.depthVolumes,
      )
      .then(
        () => {
          this._notifyingService.notify(NotifyEvents.Infrastructure.Refresh.DepthSetting, { date: this.copyDate });
          this._mdDialog.hide(true);
        },
        () => {
          this._languageService.whoops();
        },
      );
  }

  public closeDialog() {
    this._mdDialog.hide();
  }
}

angular.module('app.account').controller('CopySettingsDialogController', CopySettingsDialogController);
