import * as angular from 'angular';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { SWANConstants } from '@common/SWANConstants';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { DayNumberService } from '@services/day-number.service';
import { BaseController } from 'src/app/base.controller';

export class EquipmentDialogController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _timeout: angular.ITimeoutService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _notifyingService: NotifyingService;

  public minDate = SWANConstants.MinDate;
  public infraEquipments: fuse.infraEquipmentDto[];
  private infra: fuse.infrastructureDto;
  public equipment: fuse.infraEquipmentDto;
  public availableAssetClasses: fuse.dataInputAssetClassDto[];
  public equipmentForm: angular.IFormController;
  public title: string;
  public isNewEquipment = false;
  private allEquipments: fuse.infraEquipmentDto[];
  public selectedEquipment = null as fuse.infraEquipmentDto;
  public isAdd = true;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    $timeout: angular.ITimeoutService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    infra: fuse.infrastructureDto,
    equipment: fuse.infraEquipmentDto,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._timeout = $timeout;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._notifyingService = NotifyingService;

    this.infra = infra;
    this.equipment = angular.copy(equipment);
    this.isAdd = this.equipment == null;

    const dataInputIds = SWANConstants.dataInputAssetClasses
      .filter((a) => a.assetClassId == this.infra.assetClassId)
      .map((a) => a.dataInputId);
    this.availableAssetClasses = SWANConstants.dataInputAssetClasses.filter(
      (a) => a.isParent && !a.isGroupLink && dataInputIds.some((b) => b == a.dataInputId),
    );
    if (this.isAdd) {
      this.isNewEquipment = true;

      const adjustedTodayDay = DayNumberService.convertBrowserDateTimeToLocaleDayNumber();

      this.equipment = {
        name: null,
        assetId: null,
        infraId: infra.assetId,
        status: 'Active',
        effectiveFromDate: this._dayNumberService.convertDayNumberToLocaleDate(adjustedTodayDay),
        latitude: this.infra.latitude,
        longitude: this.infra.longitude,
        elevation: this.infra.elevation,
        tzStandardName: this.infra.tzStandardName,
        assetClassId: this.availableAssetClasses[0].assetClassId,
        dataInputId: this.availableAssetClasses[0].dataInputId,
      } as fuse.infraEquipmentDto;

      this.title = this._languageService.instant('AC.INFRASTRUCTURE.ADD_EQUIPMENT');
    } else {
      this.isNewEquipment = false;
      this.title = this._languageService.instant('AC.INFRASTRUCTURE.EDIT_EFFECTIVE_DATE');
    }
  }

  $onInit() {
    const promises = [] as angular.IPromise<void>[];
    promises.push(this.getEquipments());
    this._q.all(promises).then(() => {
      if (this.isAdd) {
        this.toggleNewEquipment();
      }
    });
  }

  private getEquipments(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._http
      .get(CommonHelper.getApiUrl(`infrastructure/allequipments?isStorage=${this.infra.isWaterStore}`))
      .then((res) => {
        this.allEquipments = res.data as fuse.infraEquipmentDto[];
        this.allEquipments.forEach((equipment) => {
          if (equipment.effectiveFromDay != null) {
            equipment.effectiveFromDate = this._dayNumberService.convertDayNumberToLocaleDate(equipment.effectiveFromDay);
          }
          if (equipment.effectiveToDay != null) {
            equipment.effectiveToDate = this._dayNumberService.convertDayNumberToLocaleDate(equipment.effectiveToDay);
          }
        });
        this.infraEquipments = this.allEquipments.filter(
          (v, i, a) => v.status == 'Active' && a.findIndex((b) => b.assetId == v.assetId) == i,
        );
        defer.resolve();
      });
    return defer.promise;
  }

  public save() {
    this.equipment.effectiveFromDay = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.equipment.effectiveFromDate);
    if (this.equipment.effectiveToDate != null) {
      this.equipment.effectiveToDay = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.equipment.effectiveToDate);
    } else {
      this.equipment.effectiveToDay = null;
    }
    let isValid = true;
    let otherRanges = this.allEquipments.filter((a) => a.status == 'Active' && a.effectiveFromDay != null);
    if (!this.isAdd) {
      // edit range
      otherRanges = otherRanges.filter((a) => a.id != this.equipment.id);
      if (this.infra.isWaterStore) {
        // water store can only have one depth meter at one time
        // should filter by (1) same asset, (2) same water store
        otherRanges = otherRanges.filter((a) => a.assetId == this.equipment.assetId || a.infraId == this.equipment.infraId);
      } else {
        otherRanges = otherRanges.filter((a) => a.assetId == this.equipment.assetId);
      }
    } else {
      // add new or existed
      if (this.infra.isWaterStore) {
        // water store can only have one depth meter at one time
        if (this.isNewEquipment) {
          // new equipment
          otherRanges = otherRanges.filter((a) => a.infraId == this.equipment.infraId);
        } else {
          // existed equipment
          otherRanges = otherRanges.filter((a) => a.assetId == this.equipment.assetId || a.infraId == this.equipment.infraId);
        }
      } else {
        // water source can have multiple meters at same time
        if (this.isNewEquipment) {
          // for new flow meter
          otherRanges = [];
        } else {
          // for existed meter
          otherRanges = otherRanges.filter((a) => a.assetId == this.equipment.assetId);
        }
      }
    }
    let checkingRange: fuse.infraEquipmentDto;
    for (const range of otherRanges) {
      checkingRange = range;
      if (this.equipment.effectiveToDay == null) {
        if (range.effectiveToDay == null) {
          isValid = false;
          break;
        } else {
          if (range.effectiveToDay >= this.equipment.effectiveFromDay) {
            isValid = false;
            break;
          }
        }
      } else {
        if (range.effectiveToDay == null) {
          if (this.equipment.effectiveToDay >= range.effectiveFromDay) {
            isValid = false;
            break;
          }
        } else {
          if (this.equipment.effectiveFromDay > range.effectiveToDay || this.equipment.effectiveToDay < range.effectiveFromDay) {
            // equipment date range is not in others date range
          } else {
            isValid = false;
            break;
          }
        }
      }
    }
    if (isValid == false) {
      let range = '';
      if (checkingRange.effectiveFromDate != null) {
        range += `${DateUtils.Locale.asDateMedium(checkingRange.effectiveFromDate)}`;
      }
      if (checkingRange.effectiveToDate != null) {
        range += ` - ${DateUtils.Locale.asDateMedium(checkingRange.effectiveToDate)}`;
      }
      if (this.infra.isWaterStore) {
        this._languageService.error('AC.EQUIP.ERROR_INTERVAL_OVERLAP_DEPTH', 'COMMON.ERROR', {
          name: checkingRange.name,
          range: range,
        });
      } else {
        this._languageService.error('AC.EQUIP.ERROR_INTERVAL_OVERLAP_WATERFLOW', 'COMMON.ERROR', {
          name: checkingRange.name,
          range: range,
        });
      }
      return;
    }
    this._http.put(CommonHelper.getApiUrl(`infrastructure/saveequipment?infraId=${this.infra.assetId}`), this.equipment).then(
      (res) => {
        const result = res.data as number;
        if (result) {
          this._mdDialog.hide(true);
          if (this.isNewEquipment) {
            const confirm = this._mdDialog
              .confirm()
              .title(this._languageService.instant('AC.INFRASTRUCTURE.CONFIGURE_EQUIPMENT'))
              .textContent(this._languageService.instant('AC.INFRASTRUCTURE.CONFIGURE_EQUIPMENT_CONTENT'))
              .ok(this._languageService.instant('AC.INFRASTRUCTURE.PROCEED'))
              .cancel(this._languageService.instant('AC.INFRASTRUCTURE.NOT_NOW'));
            this._mdDialog.show(confirm).then(
              () => {
                LocalStorageUtils.updateContextData((context) => {
                  context.assetId = result;
                });

                this._state.go('app.account.equipments.detail', {
                  id: result,
                  viewSettings: true,
                });
              },
              () => {
                // need reload observations
                this._notifyingService.notify(NotifyEvents.Infrastructure.Refresh.Observations, null);
              },
            );
          } else {
            this._notifyingService.notify(NotifyEvents.Infrastructure.Refresh.Observations, null);
          }
        } else {
          this._languageService.whoops();
        }
      },
      (error) => {
        this._languageService.whoops();
      },
    );
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public changeName() {
    if (this.equipment.name) {
      if (
        this.allEquipments.some((a) => a.assetId != this.equipment.assetId && a.name.toLowerCase() == this.equipment.name.toLowerCase())
      ) {
        this.equipmentForm.name.$setValidity('valid', false);
      } else {
        this.equipmentForm.name.$setValidity('valid', true);
      }
    } else {
      this.equipmentForm.name.$setValidity('valid', true);
    }
  }

  public changeEquipment() {
    if (this.selectedEquipment == null) {
      this.equipment.assetId = null;
      this.equipment.name = null;
      this.equipmentForm.name.$setValidity('required', false);
    } else {
      this.equipment.assetId = this.selectedEquipment.assetId;
      this.equipment.name = this.selectedEquipment.name;
      this.equipmentForm.name.$setValidity('required', true);
    }
    this.equipmentForm.name.$setTouched();
  }

  public changeFromDate() {
    if (this.equipment.effectiveToDate != null) {
      if (this.equipment.effectiveFromDate.getTime() > this.equipment.effectiveToDate.getTime()) {
        this.equipmentForm.fromDate.$setValidity('valid_date', false);
      } else {
        this.equipmentForm.fromDate.$setValidity('valid_date', true);
      }
      this.equipmentForm.toDate.$setValidity('valid_date', true);
    }
  }

  public changeToDate() {
    if (this.equipment.effectiveToDate.getTime() < this.equipment.effectiveFromDate.getTime()) {
      this.equipmentForm.toDate.$setValidity('valid_date', false);
    } else {
      this.equipmentForm.toDate.$setValidity('valid_date', true);
    }
    this.equipmentForm.fromDate.$setValidity('valid_date', true);
  }

  public clearEffectTo() {
    this.equipment.effectiveToDate = null;
    this.equipmentForm.fromDate.$setValidity('valid_date', true);
    this.equipmentForm.toDate.$setValidity('valid_date', true);
    this.equipmentForm.$setDirty();
  }

  public toggleNewEquipment() {
    if (this.isNewEquipment) {
      this.selectedEquipment = null;
    }
    this._timeout(() => {
      this.changeEquipment();
    }, 100);
  }
}

angular.module('app.account').controller('EquipmentDialogController', EquipmentDialogController);
