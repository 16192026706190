import * as angular from 'angular';
import { IGoogleTimeZone } from '@indicina/swan-shared/interfaces/google/IGoogleTimeZone';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { SWANConstants } from '@common/SWANConstants';
import { unitSizes, UnitTypes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { TableControl } from '@common/helpers/TableControl';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { LanguageService } from '@services/language.service';
import { MapService } from '@services/map.service';
import { DayNumberService } from '@services/day-number.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { DataEntityService } from '@services/data-entity.service';
import { ApiService } from '@services/api.service';
import { EquipmentDialogController } from './equipment-dialog.controller';
import { BaseController, PostSaveActions } from 'src/app/base.controller';

enum PrimaryTabEnum {
  Summary,
  Settings,
  Observations,
  Equipment,
};

export class InfrastructureController extends BaseController {
  public depthForm: angular.IFormController;
  public elevationUnit: uomUnit;
  public filteredEquipments: fuse.infraEquipmentDto[];
  public flowRateUnit: uomUnit;
  public fromDate: Date;
  public infraId: number;
  public infrastructure: fuse.infrastructureDto;
  public infrastructures: fuse.infrastructureDto[];
  public isAddButtonShown$ = false;
  public isAddDepthSetting = false;
  public isAnyChange: boolean;
  public isNewInfrastructure: boolean;
  public maxCapacity$: number | null = null;
  public minDate = SWANConstants.MinDate;
  public nameFilter = '';
  public obsFromDate: Date;
  public obsToDate: Date;
  public oriDepthSettingDate: Date;
  public primaryTab: PrimaryTabEnum;
  public refreshCount = 0;
  public reloadCount = 0;
  public selectedDate: Date | null = null;
  public showActive = true;
  public showArchived = false;
  public showSuspended = false;
  public sourceObs: any[];
  public statuses: string[];
  public summaryForm: angular.IFormController;
  public tableControl = new TableControl();
  public typeFilter = '';
  public volumeUnit: uomUnit;

  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _apiService: ApiService;
  private _dataEntityService: DataEntityService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _mapService: MapService;
  private _notifyingService: NotifyingService;

  private accountTimezoneId: string;
  private depthVolumes: fuse.depthVolumeDto[] = [];
  private equipments = [] as fuse.infraEquipmentDto[];
  private isDepthSettingValid = true;
  private map: google.maps.Map;
  private marker: google.maps.Marker;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    ApiService: ApiService,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    MapService: MapService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._apiService = ApiService;
    this._dataEntityService = DataEntityService;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._mapService = MapService;
    this._notifyingService = NotifyingService;

    this.obsToDate = new Date();
    this.obsFromDate = new Date();
    this.obsFromDate.setDate(this.obsToDate.getDate() - 30);

    this.statuses = SWANConstants.AccountStatuses;
    this.volumeUnit = UnitOfMeasureService.getUnits(UnitTypes.Volume, unitSizes.large);
    this.elevationUnit = UnitOfMeasureService.getUnits(UnitTypes.Elevation, unitSizes.normal);
    this.flowRateUnit = UnitOfMeasureService.getUnits(UnitTypes.FlowRate, unitSizes.large);

    this.scope.$watchGroup(['vm.nameFilter', 'vm.typeFilter', 'vm.showActive', 'vm.showArchived', 'vm.showSuspended'], () => {
      if (this.equipments.length) {
        this.filterEquipments();
      }
    });

    this._notifyingService.subscribe(NotifyEvents.App.SaveChanges.Infrastructure, this.scope, (_event: angular.IAngularEvent, data: PostSaveActions) => {
      this.saveChanges(data);
    });

    this._notifyingService.subscribe(NotifyEvents.Infrastructure.Refresh.DepthSetting, this.scope, (_event: angular.IAngularEvent, data) => {
      this.getObservations();
      this.onRefreshDepthSetting(data.date);
    });

    this._notifyingService.subscribe(NotifyEvents.Infrastructure.Refresh.Observations, this.scope, (_event: angular.IAngularEvent, data) => {
      this.getObservations();
    });
  }

  $onInit() {
    this.accountTimezoneId = this.account.timezoneId;
    this.infraId = Number(this._state.params.id);

    const promises = [] as angular.IPromise<unknown>[];

    promises.push(this._mapService.isGoogleMapsJSApiLoaded);

    if (!this.infraId) {
      this.infraId = null;
      this.isNewInfrastructure = true;
      this.isAnyChange = true;
      this._dataEntityService.hasDirtyCustomForm = true;
      const assetClass = SWANConstants.assetClasses.find((a) => a.id == this._state.params.assetClassId);
      this.infrastructure = {
        name: this._state.params.name,
        status: 'Active',
        assetClassId: assetClass.id,
        assetClassName: assetClass.name,
        latitude: this.account.latitude,
        longitude: this.account.longitude,
        isWaterStore: assetClass.storage,
      } as fuse.infrastructureDto;
      this.depthVolumes = [];
      this.fromDate = this._dayNumberService.convertBrowserDateTimeToLocaleDate();
    } else {
      promises.push(this.getInfrastructure(this.infraId));
      promises.push(this.getDepthSettings(this.infraId));
      promises.push(this.getInfraEquipments());

      this.getObservations();
    }

    promises.push(this.getInfrastructures());

    this._q.all(promises).then(() => {
      this.showLocationMap();
      this.calculateEvalation();
      this.getTimezone();
      this.filterEquipments();

      if (!this.isNewInfrastructure) {
        const isWaterStore = this.infrastructure.isWaterStore;

        if (isWaterStore) {
          this.primaryTab = PrimaryTabEnum.Observations;
        } else {
          this.primaryTab = isWaterStore ? 3 : 1;
        }
      } else {
        this.primaryTab = PrimaryTabEnum.Summary;
      }
    });
  }

  private onRefreshDepthSetting(date: Date) {
    this.getDepthSettings(this.infraId).then(() => {
      this.selectedDate = date.clone();
    });
  }

  private showLocationMap() {
    const latlng = new google.maps.LatLng(this.infrastructure.latitude, this.infrastructure.longitude);
    const mapContainer = document.getElementById('infrastructure-google-map');

    if (mapContainer) {
      if (!this.map) {
        const mapOptions = {
          cameraControl: false,
          center: latlng,
          fullscreenControl: false,
          mapTypeControl: false,
          mapTypeId: google.maps.MapTypeId.HYBRID,
          rotateControl: false,
          streetViewControl: false,
          tilt: 0,
          zoom: 16,
          zoomControl: true,
        } as google.maps.MapOptions;

        this.map = new google.maps.Map(mapContainer, mapOptions);
      }

      this.marker = new google.maps.Marker({
        draggable: true,
        icon: {
          anchor: new google.maps.Point(0, 36),
          origin: new google.maps.Point(0, 0),
          scaledSize: new google.maps.Size(28, 36),
          url: `assets/icons/mapicons/${this.infrastructure.assetClassId}.svg`,
        } as google.maps.Icon,
        map: this.map,
        position: latlng,
      });

      this.marker.addListener('dragend', (event: google.maps.MapMouseEvent) => {
        this.infrastructure.latitude = parseFloat(event.latLng.lat().toFixed(5));
        this.infrastructure.longitude = parseFloat(event.latLng.lng().toFixed(5));
        this.calculateEvalation();
        this.getTimezone();
        this.isAnyChange = true;
        this._dataEntityService.hasDirtyCustomForm = true;
      });
    }
  }

  private calculateEvalation() {
    if (this.infrastructure.latitude == null || this.infrastructure.longitude == null) {
      this.infrastructure.elevation = null;
    } else {
      this._mapService.getElevation(this.infrastructure.latitude, this.infrastructure.longitude).then((res) => {
        this.infrastructure.elevation = res;
      });
    }
  }

  private getTimezone() {
    if (this.infrastructure.latitude == null || this.infrastructure.longitude == null) {
      this.infrastructure.timezoneId = null;
    } else {
      this._mapService.getTimeZone(this.infrastructure.latitude, this.infrastructure.longitude).then(
        (res) => {
          const result = JSON.parse(res) as IGoogleTimeZone;

          if (result.status == 'ZERO_RESULTS') {
            this.infrastructure.timezoneId = null;
            this.infrastructure.tzStandardName = null;
            this.summaryForm.latitude.$setValidity('valid', false);
            this.summaryForm.longitude.$setValidity('valid', false);
          } else {
            this.infrastructure.timezoneId = result.timezonename;
            this.infrastructure.tzStandardName = JSON.stringify({
              dstoffset: result.dstoffset,
              rawoffset: result.rawoffset,
              timezoneid: result.timezoneid,
              timezonename: result.timezonename,
            });
            this.summaryForm.latitude.$setValidity('valid', true);
            this.summaryForm.longitude.$setValidity('valid', true);
          }
        },
        () => {
          this.infrastructure.timezoneId = null;
          this.summaryForm.latitude.$setValidity('valid', false);
          this.summaryForm.longitude.$setValidity('valid', false);
        },
      )
      .finally(() => {
        this.summaryForm.latitude.$setTouched();
        this.summaryForm.longitude.$setTouched();
      });
    }
  }

  private getInfrastructures(): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._http.get(CommonHelper.getApiUrl('infrastructure/infrastructures')).then((res) => {
      this.infrastructures = res.data as fuse.infrastructureDto[];
      defer.resolve();
    })
    .catch((error) => {
      defer.reject(error);
    });

    return defer.promise;
  }

  private getInfrastructure(infrastructureId: number): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._http.get(CommonHelper.getApiUrl(`infrastructure/infrastructure?assetid=${infrastructureId}`)).then((res) => {
      this.infrastructure = res.data as fuse.infrastructureDto;

      defer.resolve();
    })
    .catch((reason) => {
      defer.reject(reason);
    });

    return defer.promise;
  }

  private getDepthSettings(assetId: number): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._http.get(CommonHelper.getApiUrl(`infrastructure/depthsettings?assetid=${assetId}`)).then((res) => {
      this.depthVolumes = res.data as fuse.depthVolumeDto[];

      this.depthVolumes.forEach((depthVolume) => {
        depthVolume.date = this._dayNumberService.convertDayNumberToDate(depthVolume.dayNumber);
        depthVolume.effectiveFromDate = this._dayNumberService.convertDayNumberToDate(depthVolume.effectiveFromDay);
        depthVolume.effectiveToDate = this._dayNumberService.convertDayNumberToDate(depthVolume.effectiveToDay);
      });

      this.maxCapacity$ = Math.max(...this.depthVolumes.map((a) => a.volumeKL));

      defer.resolve();
    })
    .catch((reason) => {
      defer.reject(reason);
    });

    return defer.promise;
  }

  private getInfraEquipments(): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._http.get(CommonHelper.getApiUrl(`infrastructure/infraequipments?infraId=${this.infraId}`)).then((res) => {
      this.equipments = res.data as fuse.infraEquipmentDto[];

      this.equipments.forEach((equipment) => {
        if (equipment.effectiveFromDay != null) {
          equipment.effectiveFromDate = this._dayNumberService.convertDayNumberToLocaleDate(equipment.effectiveFromDay);
        }

        if (equipment.effectiveToDay != null) {
          equipment.effectiveToDate = this._dayNumberService.convertDayNumberToLocaleDate(equipment.effectiveToDay);
        }
      });

      defer.resolve();
    })
    .catch((reason) => {
      defer.reject(reason);
    });

    return defer.promise;
  }

  public gotoInfrastructures() {
    this._state.go('app.account.infrastructures');
  }

  public gotoInfrastructure(assetId: number) {
    this._state.go('app.account.infrastructures.detail', { id: assetId });
  }

  public saveChanges(postSaveActions: PostSaveActions= null) {
    let isValid = true;

    if (this.summaryForm.$invalid) {
      isValid = false;
      this.primaryTab = PrimaryTabEnum.Summary;
      this.summaryForm.name.$setTouched();
      this.summaryForm.latitude.$setTouched();
      this.summaryForm.longitude.$setTouched();
    } else if (!this.isDepthSettingValid) {
      isValid = false;
      this.primaryTab = PrimaryTabEnum.Settings;
    }

    if (isValid == false) {
      const alert = this._languageService.fixErrorsDialog();

      this._mdDialog.show(alert).then(() => {
        this._mdDialog.hide();
      });

      return;
    }

    this.saveInfrastructure().then(() => {
      const promises = [] as angular.IPromise<void>[];

      if (this.infrastructure.isWaterStore && this.depthVolumes.length) {
        const shouldReload = !super.hasAnyPostSaveActions(postSaveActions);

        promises.push(this.saveDepthVolumes(shouldReload));
      }

      this._q.all(promises).then(() => {
        this._dataEntityService.hasDirtyCustomForm = false;
        this._languageService.success('COMMON.CHANGES_SAVED');

        if (super.executeAnyPostSaveActions(postSaveActions)) {
          return;
        }

        this.isAnyChange = false;
        this.isNewInfrastructure = false;
        this.isAddDepthSetting = false;
      });
    })
    .catch(() => {
      this._languageService.whoops();
    });
  }

  private saveInfrastructure(): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._http.put(CommonHelper.getApiUrl('infrastructure/infrastructure'), this.infrastructure).then((res) => {
      if (this.isNewInfrastructure) {
        const result = res.data as number;

        this.infraId = result;
        this.infrastructure.assetId = this.infraId;
        this.isNewInfrastructure = false;
      }

      this.getInfrastructure(this.infrastructure.assetId);
      this.isAnyChange = false;
      this._dataEntityService.hasDirtyCustomForm = false;

      defer.resolve();
    })
    .catch((error) => {
      defer.reject(error);
    });

    return defer.promise;
  }

  private saveDepthVolumes(shouldReload: boolean): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    // only given a value when depths are changed
    if (!this.selectedDate) {
      defer.resolve();

      return defer.promise;
    }

    const filteredDepthVolumes = this.depthVolumes.filter((a) => a.date.getTime() == this.selectedDate.getTime());
    let oriDayNumber = null;

    if (this.oriDepthSettingDate) {
      oriDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.oriDepthSettingDate);
    }

    this._http.put(
      CommonHelper.getApiUrl(`infrastructure/savedepthvolumes?assetid=${this.infrastructure.assetId}&oridaynumber=${oriDayNumber}`),
      filteredDepthVolumes,
    ).then(() => {
      if (shouldReload) {
        this.getObservations();
        this.refreshCount++;
      };

      defer.resolve();
    },
    () => {
      defer.reject();
    });

    return defer.promise;
  }

  public rejectChanges() {
    const promises = [] as angular.IPromise<void>[];

    promises.push(this.getInfrastructure(this.infrastructure.assetId));
    promises.push(this.getDepthSettings(this.infrastructure.assetId));

    this._q.all(promises).then(() => {
      this.calculateEvalation();
      this.getTimezone();
      this.maxCapacity$ = Math.max(...this.depthVolumes.map((a) => (a.volumeKL == null ? 0 : a.volumeKL)));
      this.isAnyChange = false;
      this.isAddDepthSetting = false;
      this.selectedDate = null;
      this._dataEntityService.hasDirtyCustomForm = false;

      const bounds = this.map.getBounds();

      if (bounds != null) {
        const northeast = bounds.getNorthEast();
        const southwest = bounds.getSouthWest();

        if (
          southwest.lat() < this.infrastructure.latitude &&
          this.infrastructure.latitude < northeast.lat() &&
          southwest.lng() < this.infrastructure.longitude &&
          this.infrastructure.longitude < northeast.lng()
        ) {
          // do nothing
        } else {
          this.map.setCenter(this.marker.getPosition());
        }
      }

      this.refreshCount++;
    });
  }

  public changeLocation() {
    this.marker.setPosition(new google.maps.LatLng(this.infrastructure.latitude, this.infrastructure.longitude));
    this.calculateEvalation();
    this.getTimezone();
    this.changeInfrastructure();

    const bounds = this.map.getBounds();
    const northeast = bounds.getNorthEast();
    const southwest = bounds.getSouthWest();

    if (
      southwest.lat() < this.infrastructure.latitude &&
      this.infrastructure.latitude < northeast.lat() &&
      southwest.lng() < this.infrastructure.longitude &&
      this.infrastructure.longitude < northeast.lng()
    ) {
      // do nothing
    } else {
      this.map.setCenter(this.marker.getPosition());
    }
  }

  public changeName() {
    this.isAnyChange = true;
    this._dataEntityService.hasDirtyCustomForm = true;

    if (this.infrastructure.name) {
      if (this.infrastructures.some((a) => a.assetId != this.infrastructure.assetId && a.name.toLowerCase() == this.infrastructure.name.toLowerCase())) {
        this.summaryForm.name.$setValidity('valid', false);
      } else {
        this.summaryForm.name.$setValidity('valid', true);
      }
    } else {
      this.summaryForm.name.$setValidity('valid', true);
    }
  }

  public changeInfrastructure() {
    this.isAnyChange = true;
    this._dataEntityService.hasDirtyCustomForm = true;
  }

  public add() {
    const activeTabs = angular.element('md-tab-item.md-active').toArray();

    if (activeTabs.some((a) => a.innerHTML.includes('settings'))) {
      this.isAddDepthSetting = true;
    } else if (activeTabs.some((a) => a.innerHTML.includes('equipment'))) {
      this.addEquipment();
    }
  }

  private addEquipment() {
    this._mdDialog.show({
      clickOutsideToClose: false,
      escapeToClose: false,
      controller: EquipmentDialogController,
      controllerAs: 'vm',
      parent: angular.element(document.body),
      templateUrl: 'src/app/pages/account/views/infrastructure/equipment-dialog.html',
      locals: {
        infra: this.infrastructure,
        equipment: null,
      },
    }).then((res) => {
      if (res) {
        this._languageService.success('COMMON.CHANGES_SAVED');
        this.getInfraEquipments().then(() => {
          this.filterEquipments();
        });
      }
    });
  }

  public editEquipment(equipment: fuse.infraEquipmentDto) {
    this._mdDialog.show({
      clickOutsideToClose: false,
      escapeToClose: false,
      controller: EquipmentDialogController,
      controllerAs: 'vm',
      parent: angular.element(document.body),
      templateUrl: 'src/app/pages/account/views/infrastructure/equipment-dialog.html',
      locals: {
        infra: this.infrastructure,
        equipment: equipment,
      },
    }).then((res) => {
      if (res) {
        this._languageService.success('COMMON.CHANGES_SAVED');
        this.getInfraEquipments().then(() => {
          this.filterEquipments();
        });
      }
    });
  }

  public clearFilter(): void {
    this.nameFilter = '';
    this.typeFilter = '';
  }

  private filterEquipments() {
    if (this.equipments) {
      this.filteredEquipments =
        this.equipments
        .filter((a) => a.infraId == this.infrastructure.assetId)
        .filter((a) => a.name.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1);

      this.filteredEquipments = this.filteredEquipments.filter(
        (a) => a.assetClassName.toLowerCase().indexOf(this.typeFilter.toLowerCase()) > -1
      );

      if (!this.showActive) {
        this.filteredEquipments = this.filteredEquipments.filter((a) => a.status !== 'Active');
      }

      if (!this.showArchived) {
        this.filteredEquipments = this.filteredEquipments.filter((a) => a.status !== 'Archived');
      }

      if (!this.showSuspended) {
        this.filteredEquipments = this.filteredEquipments.filter((a) => a.status !== 'Suspended');
      }
    }
  }

  public gotoEquipment(equip: fuse.infraEquipmentDto) {
    LocalStorageUtils.updateContextData((context) => {
      context.assetId = equip.assetId;
    });

    this._state.go('app.account.equipments.detail', { id: equip.assetId });
  }

  public detachEquipment(equip: fuse.infraEquipmentDto) {
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('COMMON.DETACH'))
      .htmlContent(
        this._languageService.instant('AC.INFRASTRUCTURE.DETACH_EQUIPMENT', {
          type: this.infrastructure.isWaterStore
            ? this._languageService.instant('COMMON.DEPTH_METER')
            : this._languageService.instant('COMMON.FLOW_METER'),
          name: equip.name,
          source: this.infrastructure.isWaterStore
            ? this._languageService.instant('COMMON.WATER_STORE')
            : this._languageService.instant('COMMON.WATER_SOURCE'),
          sourceName: this.infrastructure.name,
        }),
      )
      .escapeToClose(false)
      .ok(this._languageService.instant('COMMON.CANCEL'))
      .cancel(this._languageService.instant('COMMON.CONFIRM'));

    // NOTE: Reverse ok/cancel
    this._mdDialog.show(confirm).then(
      () => {
        // press cancel button
      },
      () => {
        // press confirm button
        this._http.post(CommonHelper.getApiUrl(`infrastructure/detachequipment?id=${equip.id}`), null).then(
          () => {
            this._languageService.success('COMMON.CHANGES_SAVED');
            this.getInfraEquipments().then(() => {
              this.filterEquipments();
            });
          },
          () => {
            this._languageService.whoops();
          },
        );
      },
    );
  }

  private getObservations() {
    const after = (res) => {
      this.sourceObs = res;
      this.sourceObs.forEach((obs) => (obs.date = this._dayNumberService.convertDayNumberToLocaleDate(obs.dayNumber)));
      this.reloadCount++;
    }
    const fromDay = this._dayNumberService.convertDateToLocaleDayNumber(this.obsFromDate, this.accountTimezoneId);
    const toDay = this._dayNumberService.convertDateToLocaleDayNumber(this.obsToDate, this.accountTimezoneId);
    const params = { assetId: this.infraId, dayNumberFrom: fromDay, dayNumberTo: toDay };

    this._apiService.getGeneric('infrastructure/waterSourceObs', params, after);
  }

  public depthSettingChanged(isValid: boolean, date: Date, oriDate: Date) {
    this.isDepthSettingValid = isValid;
    this.oriDepthSettingDate = oriDate;
    this.selectedDate = date;
    this.isAnyChange = true;
    this._dataEntityService.hasDirtyCustomForm = true;
  }
}

angular.module('app.account').controller('InfrastructureController', InfrastructureController);
