// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#infra-equipment md-input-container {
  display: block;
  margin: 8px;
  padding: 2px;
  height: 48px;
}
#infra-equipment md-input-container .md-errors-spacer {
  min-height: 0px;
}
#infra-equipment md-input-container md-datepicker {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/account/views/infrastructure/equipment-dialog.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;AAAJ;AAEI;EACE,eAAA;AAAN;AAGI;EACE,cAAA;AADN","sourcesContent":["#infra-equipment {\r\n  md-input-container {\r\n    display: block;\r\n    margin: 8px;\r\n    padding: 2px;\r\n    height: 48px;\r\n\r\n    .md-errors-spacer {\r\n      min-height: 0px;\r\n    }\r\n\r\n    md-datepicker {\r\n      display: block;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
