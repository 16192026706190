import * as angular from 'angular';
import { SWANConstants } from '@common/SWANConstants';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class AddInfrastructureDialogController extends BaseController {
  public addForm: angular.IFormController;
  public infraName: string;
  public infraNameExists = false;
  public infrastructureTypes: fuse.assetClassDto[];
  public infraTypeId: number | null = null;

  private _mdDialog: angular.material.IDialogService;
  private _infrastructures = [] as fuse.infrastructureDto[];

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    infrastructures: fuse.infrastructureDto[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this._infrastructures = infrastructures;
    this.infrastructureTypes = SWANConstants.assetClasses.filter((a) => a.infrastructure);
  }

  public addInfrastructure() {
    const newInfrastructure = {
      name: this.infraName,
      assetClassId: this.infraTypeId,
    } as fuse.infrastructureDto;

    this._mdDialog.hide(newInfrastructure);
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public checkInfraName() {
    if (this.infraName) {
      if (this._infrastructures.some((a) => a.name.toLowerCase() == this.infraName.toLowerCase())) {
        this.addForm.infraName.$setValidity('valid', false);
      } else {
        this.addForm.infraName.$setValidity('valid', true);
      }
    } else {
      this.addForm.infrastructureName.$setValidity('valid', true);
    }
  }

  public changeType() {
    if (!this.infraTypeId) {
      this.addForm.infraType.$setValidity('valid', false);
    } else {
      this.addForm.infraType.$setValidity('valid', true);
    }
  }
}

angular.module('app.account').controller('AddInfrastructureDialogController', AddInfrastructureDialogController);
